/* @import url("https://fonts.googleapis.com/css?family=Nunito&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Lexend+Exa&family=Lexend+Giga&family=Lexend+Mega&family=Lexend+Peta&family=Lexend+Tera&family=Lexend+Zetta&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;1,600&display=swap"); */
@import url("https://use.typekit.net/pit6vlr.css");
body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
